import { Box, css, type SxProps } from '@mui/material'
import { spacing, Spinner, Text } from '@orus.eu/pharaoh'
import type { ReactElement, ReactNode } from 'react'

export type LocalLoadingStateProps = {
  children?: ReactNode
  sx?: SxProps
  className?: string
}

export function LocalLoadingState({ children, sx = {}, className }: LocalLoadingStateProps): ReactElement {
  return (
    <Box sx={{ textAlign: 'center', ...sx }} className={className}>
      <Spinner size="50" />

      <Text
        variant="body1"
        css={css`
          margin-top: ${spacing[70]};
        `}
      >
        {children ? <p>{children}</p> : <></>}
      </Text>
    </Box>
  )
}
